export const topLevelMenu = [
    {
        index: 1,
        name: 'Live Map',
        link: '/map',
        tags: 'Report,People,Fleet',
        icon: 'location/map'
    },
    {
        index: 3,
        name: 'Users',
        link: '/users',
        tags: 'Report,People,Fleet',
        icon: 'people/group'
    },
    {
        index: 4,
        name: 'Booking Log',
        link: '/bookinglog',
        tags: 'Report,People,Fleet',
        icon: 'menu/trips'
    },
]

export const genericTags = ['Report', 'People', 'Fleet'];

export const reports = {
    bookings: {
        component: () => import('@/views/fleet-manager/reports/BookingReport.vue'),
        name: 'Bookings Report',
        criteria: [
            'Booking was cancelled, expired or ended within timeframe.'
        ]
    },
    driverbehaviour: {
        component: () => import('@/views/fleet-manager/reports/DriverBehaviorReport.vue'),
        name: 'Driver Behaviour',
        criteria: [
            'Any action in the vehicle while the ignition is turned on that exceeds 4g',
            'Actions grouped to reflect accelarating, breaking, steering and possible accidents',
        ],
        hasNote: true,
    },
    fbt: {
        component: () => import('@/views/fleet-manager/reports/OvernightReport.vue'),
        name: 'Overnight & Weekend Bookings | FBT',
        criteria: [
            'Business hours from 7am to 6pm.',
            'Out of hours include weekends and national holidays',
        ],
        hasNote: true,
    },
    hubutil: {
        component: () => import('@/views/fleet-manager/reports/HubUtilisationReport.vue'),
        name: 'Hub Utilisation',
        criteria: [
            'Business hours from 7am to 6pm.',
            'Out of hours include weekends and national holidays',
        ]
    },
    pavutil: {
        component: () => import('@/views/fleet-manager/reports/PavUtilisationReport.vue'),
        name: 'PAV Utilisation',
        criteria: [
            'PAV availablity equals 24 hours per day.',
            'Business hours from 7am to 6pm.',
            'Out of hours include weekends and national holidays',
        ]
    },
    privatebookings: {
        component: () => import('@/views/fleet-manager/reports/PrivateBookingReport.vue'),
        name: 'Private Booking Reports',
        criteria: ['Booking was cancelled, expired or ended within timeframe.']
    },
    trips: {
        component: () => import('@/views/fleet-manager/reports/TripsReport.vue'),
        name: 'Trip Details',
        criteria: [
            'Start location is recorded when then ignition is turned on.',
            'End location is recorded when then ignition is turned off.',
            'Please note: Currently this report may take extremely long to load depending on search criteria.'
        ]
    },
}

export const bookingLabels = {
    type: 'Type',
    id: 'ID',
    pickup: 'Pickup',
    startString: 'Start',
    drop_off: 'Drop-off',
    endString: 'End',
    vehicle: 'Vehicle',
    price: 'Price',
    state: 'State'
}