<template>
    <VueDatepicker v-bind="$attrs" select-text="Done" cancel-text="Close" now-button-label="Today"
        :config="{ keepActionRow: !$attrs['auto-apply'] }" :prevent-min-max-navigation="true">
        <template #input-icon>
            <SvgIcon name="calendar/calendar" />
        </template>
    </VueDatepicker>
</template>
<script>
import VueDatepicker from '@vuepic/vue-datepicker';

export default {
    name: 'DatePicker',
    components: {
        VueDatepicker
    },
}
</script>
<style scoped>
.icon {
    left: 1rem;
}

:deep(button.dp__btn.dp--arrow-btn-nav) {
    padding: 0.5rem 2.5rem;
}

:deep(.dp__action_select),
:deep(.dp__range_start),
:deep(.dp__range_end),
:deep(.dp__input_icon),
:deep(.dp__active_date) {
    color: var(--font-color, #042B2F);
}

:deep(.dp__range_start),
:deep(.dp__range_end),
:deep(.dp__active_date) {
    border-color: var(--bg-secondary-color, #042B2F);
}

:deep(.dp__input) {
    padding-left: 30px;
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    border-radius: .8rem;
    background-color: var(--color-grey);
}

.white :deep(input.dp__input) {
    background-color: var(--lite-gray-white, #FFFFFF);
}

:deep(.dp__input):hover:not(.dp__input_focus) {
    border-color: var(--input-hover-color);
}

:deep(::placeholder) {
    color: rgba(92, 97, 99, 0.5);
}

:deep(.dp__month_year_wrap) {
    align-items: center;
}

:deep([type=button]) {
    padding: 1rem;
    display: inherit;
}
</style>