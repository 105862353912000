<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/licence.png" nextButtonName="Next"
        :stepIndex="1" className="small-gap">
        <div class="h1 reg">Driver Licence</div>
        <RowDiv>
            <div class="col-12">
                <div class="instructions">
                    <div class="b1 semi">Birth date</div>
                    <div class="b2 reg">
                        Enter your birthdate from your drivers licence
                    </div>
                </div>
            </div>
            <div class="col-12">
                <DatePicker v-model="date" format="dd/MM/yyyy" model-type="dd-MM-yyyy" class="date-picker"
                    :enable-time-picker="false" placeholder="Date (dd/mm/yyyy)" :start-date="tenYearsAgo"
                    :max-date="tenYearsAgo" :no-today="true" :auto-apply="true" :text-input="!isMobile"
                    @update:model-value="updateBirthDate"
                    :action-row="{ showNow: false, showPreview: true, showSelect: true, showCancel: true }" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col-12">
                <div class="instructions">
                    <div class="b1 semi">Licence number</div>
                    <div class="b2 reg">
                        Enter your licence number found at x on y
                    </div>
                </div>
            </div>
            <div class="col">
                <InputComponent v-model="licenceNumber" type="text" placeholder="Licence number"
                    :validation="['required', 'licence']" @validation="message => handleKeyPress('number', message)" />
            </div>
        </RowDiv>
        <RowDiv>
            <div class="col-12">
                <div class="instructions">
                    <div class="b1 semi">Licence version</div>
                    <div class="b2 reg">
                        Enter your licence number found at x on y
                    </div>
                </div>
            </div>
            <div class="col">
                <InputComponent v-model="licenceVersion" type="text" placeholder="Version"
                    :validation="['required', 'numeric', { rule: 'length', length: 3 }]"
                    @validation="message => handleKeyPress('version', message)" />
            </div>
        </RowDiv>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy"
                target="_blank">Privacy Policy</a></template>
    </OnboardingCard>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import DatePicker from '@/components/common/DatePicker.vue';
export default {
    data() {
        return {
            lastName: '',
            birthDate: '',
            licenceNumber: '',
            licenceVersion: '',
            tenYearsAgo: '',
            date: null,
            isValid: {
                number: false,
                version: false,
            }
        }
    },
    components: {
        OnBoardingCard,
        DatePicker,
    },
    mounted() {
        this.birthDate = this.date = this.onboarding?.date_of_birth || '';
        this.lastName = this.onboarding?.last_name || '';

        const today = new Date();
        const tenYearsAgo = new Date(today.getFullYear() - 15, today.getMonth(), today.getDate());
        this.tenYearsAgo = tenYearsAgo.toISOString().split('T')[0];
    },
    computed: {
        ...mapState(['onboarding', 'isMobile'])
    },
    watch: {
        onboarding: {
            handler(newValue) {
                if (newValue) {
                    this.lastName = newValue.last_name || '';
                    this.birthDate = newValue.date_of_birth || '';
                    this.licenceNumber = newValue.licence_number || '';
                    this.licenceVersion = newValue.licence_version || '';
                    this.setNextButtonEnabled(this.birthDate && this.licenceNumber && this.licenceVersion);
                    this.date = this.birthDate;
                }
            },
            immediate: true,
            deep: true,
        }
    },
    methods: {
        updateBirthDate(date) {
            this.birthDate = date;
            this.setNextButtonEnabled(this.birthDate && this.isValid.number && this.isValid.version);
        },
        handleSubmit() {
            this.setIsLoading(true);
            this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {
                date_of_birth: this.birthDate,
                licence_number: this.licenceNumber,
                licence_version: this.licenceVersion,
            }, { headers: { token: sessionStorage.getItem('onboardingToken') } }).then(() => {
                this.setIsLoading(false);
                this.$router.push('/onboarding/policies');
            }).catch(error => {
                this.setIsLoading(false);
                this.$bus.emit('show-toast-message', {
                    text: 'There was an error during onboarding.',
                    error: true,
                });
                console.error("An error occurred while logging in:", error);
            });
        },
        handleKeyPress(name, message = '') {
            this.isValid[name] = (message === '');
            this.setNextButtonEnabled(this.birthDate && this.isValid.number && this.isValid.version);
        },
        ...mapMutations(['setOnboarding', 'setNextButtonEnabled', 'setIsLoading'])
    }
}

</script>
<style scoped>
.date-picker {
    font-size: 1.6rem;
}

.date-picker :deep(input) {
    padding-left: 3rem;
}

:deep(.dp__action_select),
:deep(.dp__active_date) {
    border-color: var(--bg-secondary-color, #042B2F);
    color: var(--font-color, #042B2F);
}

.image {
    flex: 1;
}

.onboarding-stack {
    gap: .25rem;
}

.form-field-value,
input.form-field-value {
    color: var(--font-color, #042B2F);
    text-align: left;
    font-size: var(--xsmall-font-size, 1.2rem);
    font-weight: var(--input-fields-label-1-font-weight, 400);
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    align-self: stretch;
}

.forms-date {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
}

.bo-forms-no-corners-labels {
    background: var(--color-grey, #FAFAFA);
    padding: 0.625rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex: 1;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
}

.button {
    background: var(--color-grey, #FAFAFA);
    padding: 1.2rem 1rem 1.2rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    width: 3rem;
    position: relative;
}
</style>