<template>
    <OnBoardingCard displayImage="/images/resized/image4.png">
        <div class="h1 reg">Congratulations!</div>
        <div class="block-text">
            You are all set up and ready to rooooooolllllllll whooooop.
            <br />
            <br />
            What can you do first?
            <br />
            <br />
            Check out the carpools
            <br />
            Add Zilch for personal use
            <br />
            Book a car
            <br />
        </div>
        <div class="buttons">
            <SelectableButton @click="goToHome">
                <div class="btn-anchor bu1-semi">Go to Home</div>
            </SelectableButton>
        </div>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { getAuth } from '@/utils/functions';
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
    components: {
        OnBoardingCard,
    },
    computed: {
        ...mapState(['isMobile']),
    },
    methods: {
        goToHome() {
            this.loadProfile(getAuth()).then(() => {
                this.setLoggedIn(true);
                this.$router.push('/home');
            }).catch(() => {
                console.error('Could not load the profile');
                this.$router.push({ name: 'Home' });
            });
        },
        handleBackButton() {
            this.$router.back();
        },
        ...mapMutations(['setLoggedIn']),
        ...mapActions(['loadProfile']),
    }
}
</script>