import { createStore } from 'vuex';
import { getAuth, setAuth, setIsAdmin, setWelcomeSession } from '@/utils/functions';
import { genericTags, topLevelMenu } from '@/utils/static';
import axios from 'axios';
import ENV from '@/env';

const store = createStore({
    state() {
        return {
            isLoggedIn: false, // Initial state is that the user is not logged in
            isFramed: false,
            isAdmin: false,
            onboarding: null,
            onboardingLoading: false,
            nextButtonEnabled: false,
            isMobile: window.innerWidth <= 768,
            widthBreakPoint: 768,
            isTokenExpired: false,
            notifications: [],
            searchCriteria: null,
            profile: null,
            refreshAttempted: false,
            menu: null,
            report: null,
            showWelcome: false,
            paymentMethods: null,
            costCentres: null,
            expenseCodes: null,
            company: null,
        };
    },
    mutations: {
        setShowWelcome(state, value) {
            state.showWelcome = value;
            setWelcomeSession(value);
        },
        setRefreshAttempted(state, value) {
            state.refreshAttempted = value;
        },
        setIsLoading(state, value) {
            state.onboardingLoading = value;
        },
        setMenu(state, value) {
            state.menu = value;
        },
        setReport(state, value) {
            state.report = value;
        },
        setIsFramed(state, value) {
            state.isFramed = value;
        },
        setIsAdmin(state, value) {
            state.isAdmin = value;
            setIsAdmin(value);
        },
        setLoggedIn(state, value) {
            state.isLoggedIn = value;
        },
        setOnboarding(state, { key, value }) {
            if (state.onboarding === null) state.onboarding = {};
            state.onboarding[key] = value;
        },
        setProfile(state, { key, value }) {
            if (state.profile === null) state.profile = {};
            state.profile[key] = value;
        },
        setSearchCriteria(state, { key, value }) {
            if (state.searchCriteria === null) state.searchCriteria = {};
            if (value === null) {
                delete state.searchCriteria[key];
            } else {
                state.searchCriteria[key] = value;
            }
        },
        clearSearchCriteria(state) {
            sessionStorage.removeItem('searchParams');
            state.searchCriteria = null;
        },
        setNextButtonEnabled(state, value) {
            state.nextButtonEnabled = value;
        },
        setIsMobile(state, payload) {
            state.isMobile = payload;
        },
        setWidthBreakPoint(state, payload) {
            state.widthBreakPoint = payload;
        },
        setTokenExpired(state, value) {
            state.isTokenExpired = value;
        },
        pushNotification(state, value) {
            state.notifications.push(value);
        },
        removeNotification(state, index) {
            state.notifications.splice(index, 1);
        },
        clearNotifications(state) {
            state.notifications = [];
        },
        logout(state) {
            // Save the properties you don't want to clear
            const isMobile = state.isMobile;
            const widthBreakPoint = state.widthBreakPoint;

            // Reset all other state properties
            Object.assign(state, {
                isLoggedIn: false,
                isFramed: false,
                isAdmin: false,
                onboarding: null,
                nextButtonEnabled: false,
                isTokenExpired: false,
                notifications: [],
                searchCriteria: null,
                profile: null,
                refreshAttempted: false,
                menu: null,
                report: null,
                showWelcome: false,
                paymentMethods: null,
                costCentres: null,
                expenseCodes: null,
                company: null,
            });

            // Restore the values you want to keep
            state.isMobile = isMobile;
            state.widthBreakPoint = widthBreakPoint;
        },
        loadNotifications(state) {
            let message = '';
            if (state.profile.licence_state.toLowerCase() !== 'valid') {
                message = (state.profile.licence_state.toLowerCase() === 'pending') ?
                    'Licence is pending validation. ' : 'Your licence is invalid. ';
                message += 'You are not able to book any cars before it is validated';
                state.notifications.push({ message, class: 'error' });
            }
        },
    },
    actions: {
        refreshToken({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                commit('setRefreshAttempted', true);
                axios.post(`${ENV.API_HOST}/auth/login`, {
                    refresh_token: sessionStorage.getItem('refToken'),
                }).then((response => {
                    setAuth(response.data);
                    resolve(true);
                })).catch(e => {
                    console.error('Invalid credentials, need to re-login: ', e);
                    dispatch('logout');
                    reject(e);
                })
            })
        },
        loadProfile({ commit, dispatch, state }) {
            return new Promise((resolve, reject) => {
                const accessToken = getAuth()
                axios.get(`${ENV.API_HOST}/user/details`, { headers: { Authorization: `Bearer ${accessToken}` } }).then(resp => {
                    state.profile = resp.data;
                    state.isLoggedIn = true;
                    commit('setIsAdmin', resp.data?.access === 'fleetmanager');
                    // commit('loadNotifications');
                    resolve(true);
                }).catch(e => {
                    console.error('Error fetching profile details with current accessToken:', e);
                    if (!state.refreshAttempted) {
                        dispatch('refreshToken')
                            .then(() => {
                                dispatch('loadProfile').then(() => {
                                    resolve(true)
                                }).catch(e => { reject(e) });
                            })
                            .catch(e => { reject(e) });
                    } else {
                        dispatch('logout');
                        reject(e);
                    }
                });
            })
        },
        fetchMenu({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/admin/menus`, {
                    headers: { Authorization: `Bearer ${getAuth()}` }
                }).then(response => {
                    const allTags = {};
                    const menus = Object.keys(response.data).reduce((acc, key) => {
                        const keyTags = new Set();
                        const menu = [
                            ...(key === 'top_level') ? topLevelMenu : [],
                            ...response.data[key]
                        ].sort((a, b) => a.index - b.index);
                        acc[key] = menu.map(item => {
                            const tagTemplate = (key === 'top_level') ? genericTags : [];
                            if (item.name === 'Planner') item.icon = 'location/route';
                            const tags = item.tags ? item.tags.split(',').map(tag => tag.trim()) : tagTemplate;
                            tags.forEach(tag => keyTags.add(tag));
                            return {
                                ...item,
                                tags
                            }
                        });
                        allTags[key] = Array.from(keyTags);
                        return acc;
                    }, {});
                    menus.allTags = allTags;
                    if (response.data) commit('setMenu', menus);
                    resolve(true)
                }).catch(error => {
                    console.error(error);
                    reject(error)
                })
            });
        },
        fetchPaymentMethods({ dispatch, state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/user/paymentmethods`, {
                    headers: { 'Authorization': `Bearer ${getAuth()}` }
                }).then(response => {
                    if (response.status === 200 && response.data === null) {
                        resolve(true);
                        return;
                    }
                    state.paymentMethods = response.data;
                    const corporatePayment = response.data.find(method => method.service === 'employer');
                    state.company = corporatePayment.tag;
                    dispatch('fetchCostCentres')
                        .then(() => resolve(true))
                        .catch(error => { reject(error) });
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },
        fetchCostCentres({ dispatch, state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/user/costcentres`, {
                    headers: { 'Authorization': `Bearer ${getAuth()}` }
                }).then(response => {
                    if (response.status === 200 && response.data === null) {
                        resolve(true);
                        return;
                    }
                    state.costCentres = response.data;
                    dispatch('fetchExpenseCodes')
                        .then(() => resolve(true))
                        .catch(error => { reject(error) });
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },
        fetchExpenseCodes({ state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/user/expensecodes`, {
                    headers: { 'Authorization': `Bearer ${getAuth()}` }
                }).then(response => {
                    state.expenseCodes = response.data;
                    resolve(true);
                }).catch(error => {
                    console.error(error);
                    reject(error);
                })
            });
        },
        login({ dispatch, state }, { email_address, password }) {
            return new Promise((resolve, reject) => {
                axios.post(`${ENV.API_HOST}/auth/login`, {
                    email_address, password
                }).then(response => {
                    setAuth(response.data)
                    dispatch('loadProfile').then(() => {
                        if (state.isAdmin) {
                            dispatch('fetchMenu').then(() => {
                                resolve(true);
                            }).catch(e => {
                                reject(e)
                            })
                        }
                        dispatch('fetchPaymentMethods').then(() => {
                            resolve(true)
                        }).catch(error => {
                            console.error(error);
                            reject(error);
                        })
                    }).catch(e => {
                        dispatch('logout');
                        reject(e);
                    });
                }).catch(e => {
                    dispatch('logout');
                    reject(e);
                });
            })
        },
        logout({ commit }) {
            sessionStorage.clear();
            localStorage.clear();
            commit('logout');
        },
        updateIsMobile({ commit }) {
            commit('setIsMobile', window.innerWidth <= this.state.widthBreakPoint);
        },
    }
});

export default store;
