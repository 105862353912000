<template>
    <div class="cards-navigation">
        <div v-if="(!this.$route.path.includes('join') && !this.$route.path.includes('login') && !this.$route.path.includes('verified'))"
            class="onboard-cards-top-bar-navigation" @click="handleBackButton">
            <SelectableButton className="borderless light">
                <SvgIcon name="trip/arrow-left" />
                <div class="bu1 semi">Back</div>
            </SelectableButton>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: 'currentColor'
        }
    },
    methods: {
        handleBackButton() {
            this.$router.back();
        }
    }
};
</script>
<style scoped>
.cards-navigation {
    padding: 4rem 0rem 2rem 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.mobile .cards-navigation {
    background: rgba(255, 255, 255, 0.6);
    width: -webkit-fill-available;
    padding: 3.3rem;
}

.onboard-cards-top-bar-navigation {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
}

.mobile .back {
    width: auto;
}
</style>