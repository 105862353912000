<template>
    <OnBoardingCard :nextHandler="handleSubmit" display-image="/images/resized/car1.png" nextButtonName="Start" className="small-gap">
        <div class="h1 reg">
            Welcome to
            <br />
            Zilch {{ companyName ? ' at ' + companyName : '' }}
        </div>
        <div class="h3 reg block-text">
            Lets get your personal preferences set up so you can get moving with
            Zilch.
        </div>
        <div class="list">
            <div class="instructions">
                <div class="instructions-block">
                    Set up takes a couple of minutes and covers:
                </div>
            </div>
            <div class="labels-default">
                <SvgIcon name="document/document-text" />
                <div class="labels-text">Policies</div>
            </div>
            <div class="labels-default">
                <SvgIcon name="people/employee" />
                <div class="labels-text">Drivers licence</div>
            </div>
            <div class="labels-default">
                <SvgIcon name="payment/card" />
                <div class="labels-text">Payment set up</div>
            </div>
            <div class="labels-default">
                <SvgIcon name="lock/key" />
                <div class="labels-text">Access card set up</div>
            </div>
        </div>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy" target="_blank">Privacy Policy</a></template>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapState, mapMutations } from 'vuex';
export default {
    components: {
        OnBoardingCard,
    },
    data() {
        return {
            companyName: ''
        }
    },
    computed: {
        ...mapState(['onboarding'])
    },
    beforeMount() {
        this.setNextButtonEnabled(true);
    },
    methods: {
        handleSubmit() {
            this.$router.push('/onboarding/getting-to-know');
        },
        ...mapMutations(['setNextButtonEnabled'])
    }
}
</script>
<style scoped>
:deep(.onboarding-stack) {
    gap: .25rem;
}
</style>