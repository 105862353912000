<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/image1.png" nextButtonName="Next" :stepIndex="2">
        <div class="h1 reg">
            The policies - then the keys
        </div>
        <div class="list">
            <div class="instructions">
                <div class="form-field-label b2 reg">
                    Read and agree to the policies
                </div>
            </div>
            <div class="labels-default">
                <SvgIcon name="document/document-text" />
                <div class="labels-text">Privacy policy</div>
                <SelectableButton class="small light" :is-selected="acceptedPolicy"
                    @click="openPolicyModal('Zilch Policy', 'acceptedPolicy', 'privacy')">
                    <div class="labels-text">{{ this.acceptedPolicy ? 'Agreed' : 'Read' }}</div>
                    <SvgIcon :name="acceptedPolicyIcon" />
                </SelectableButton>
            </div>
            <div class="labels-default">
                <SvgIcon name="document/document-text" />
                <div class="labels-text">Terms and condition</div>
                <SelectableButton class="small light" :is-selected="acceptedTCs"
                    @click="openPolicyModal('Terms and Conditions', 'acceptedTCs', 'terms')">
                    <div class="labels-text">{{ this.acceptedTCs ? 'Agreed' : 'Read' }}</div>
                    <SvgIcon :name="acceptedTCsIcon" />
                </SelectableButton>
            </div>
        </div>
        <PolicyModal v-if="isPolicyModalOpen" :title="modalTitle" :name="modalName" :source="modalSource"
            @close="closePolicyModal" @accept="acceptModal"></PolicyModal>

        <div v-if="isModalOpen" class="modal-overlay">
            <div class="modal">
                <div class="modal-content">
                    <CardDiv>
                        <header>Policy Agreements</header>
                        <p>Please read and accept both policies before continuing. Thank you.</p>
                        <footer class="is-right">
                            <a class="button primary" @click="isModalOpen = false">Close</a>
                        </footer>
                    </CardDiv>
                </div>
            </div>
        </div>
    </OnBoardingCard>
</template>

<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import PolicyModal from '../../components/PolicyModal.vue';
import { mapState, mapMutations } from 'vuex';
export default {
    data() {
        return {
            isModalOpen: false,
            isPolicyModalOpen: false,
            modalSource: '',
            modalTitle: '',
            acceptedTCs: false,
            acceptedPolicy: false,
            acceptedTCsIcon: 'icon/export',
            acceptedPolicyIcon: 'icon/export',
        };
    },
    computed: {
        ...mapState(['onboarding'])
    },
    mounted() {
        this.setNextButtonEnabled(false);
    },
    methods: {
        openPolicyModal(title, name, file) {
            this.$axios.get(`${this.$env.API_HOST}/referenceData/legal/${file}`)
                .then(response => {
                    this.modalTitle = title;
                    this.modalSource = response.data;
                    this.modalName = name;
                    this.isPolicyModalOpen = true;
                }).catch(error => {
                    console.error('Error fetching source file:', error);
                });
        },
        acceptModal(name) {
            this[name + 'Icon'] = 'feedback/tick-circle';
            this[name] = true;
            this.isPolicyModalOpen = false;
            this.setNextButtonEnabled(this.acceptedPolicy && this.acceptedTCs);
        },
        closePolicyModal() {
            this.isPolicyModalOpen = false;
            this.modalSource = '';
        },
        handleSubmit() {
            if (this.acceptedPolicy && this.acceptedTCs) {
                this.$router.push('/onboarding/personal-use');
            } else {
                this.isModalOpen = true;
            }
        },
        ...mapMutations(['setNextButtonEnabled'])
    },
    components: {
        PolicyModal,
        OnBoardingCard,
    }
};
</script>
