export function getLongLatDistance(coords1, coords2) {
    const earthRadius = 6371;
    const dLat = toRad(+coords2.latitude - +coords1.latitude);
    const dLong = toRad(+coords1.longitude - +coords2.longitude);
    const lat1 = toRad(+coords1.latitude);
    const lat2 = toRad(+coords2.latitude);

    const x = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const y = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));
    return earthRadius * y;
}

export function ucFirst(value) {
    return value?.charAt(0).toUpperCase() + value?.slice(1);
}

export function parseDate(date) {
    const [day, month, year] = date.split('-');
    const mo = ((month < 10) ? '0' : '') + month;
    const da = ((day < 10) ? '0' : '') + day;
    return new Date(`${year}-${mo}-${da} 00:00`);
}

export function parseDateTime(date, time) {
    if (!date || !time) return null;
    const [day, month, year] = date.split('-');
    return new Date(`${year}-${month}-${day} ${time}`);
}

export function getDateTimeStringFromUnixTime(unixtime) {
    const date = new Date(unixtime * 1000);
    const hours = ((date.getHours() < 10) ? '0' : '') + date.getHours();
    const minutes = ((date.getMinutes() < 10) ? '0' : '') + date.getMinutes();
    return {
        date: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`,
        time: `${hours}:${minutes}`,
    }
}

export function getMinTime(refDate, offset = null) {
    const today = new Date();
    let minTime = getSeconds(refDate.getTime());
    if (today.getTime() > refDate.getTime()) {
        minTime = getSeconds(today.getTime());
    }
    if (offset) {
        minTime += offset
    }
    return minTime * 1000;
}

export function openMap(hub) {
    if (!hub) return;

    const { latitude, longitude } = hub;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
        // iOS-specific handling (tries Apple Maps first)
        window.open(`maps://?q=${latitude},${longitude}`);
    } else if (isAndroid) {
        // Android-specific handling (tries Google Maps)
        window.open(`geo:${latitude},${longitude}?q=${latitude},${longitude}`);
    } else {
        // Fallback to Google Maps in a browser
        window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`);
    }
}

export function setIsAdmin(isAdmin) {
    sessionStorage.setItem('isAdminAccess', isAdmin);
}

export function getIsAdmin() {
    return sessionStorage.getItem('isAdminAccess');
}

export function setWelcomeSession(value) {
    sessionStorage.setItem('welcomeShown', value);
}

export function showWelcomeScreen() {
    return sessionStorage.getItem('welcomeShown');
}

export function setAuth(token) { 
    sessionStorage.setItem('accToken', token.access_token);
    sessionStorage.setItem('refToken', token.refresh_token);
    localStorage.setItem('accToken', token.access_token);
}

export function getAuth() { 
    return sessionStorage.getItem('accToken') ?? localStorage.getItem('accToken');
}

function toRad(value) {
    return +value * (Math.PI / 180);
}

function getSeconds(milliseconds) {
    return Math.floor(milliseconds / 1000);
}