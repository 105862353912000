import '@vuepic/vue-datepicker/dist/main.css';
import '@/styles/main.css';
import { createApp } from 'vue';
import axios from 'axios';
import stripe from 'stripe';
import App from '@/App.vue';
import ENV from '@/env';
import eventBus from '@/eventBus';
import GlobalComponents from '@/global-components';
import router from '@/router';
import store from '@/store';

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const app = createApp(App);

app.config.globalProperties.$bus = eventBus;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$env = ENV;
app.config.globalProperties.$stripe = stripe(ENV.STRIPE_SECRET_KEY);

window.addEventListener('resize', () => {
    store.dispatch('updateIsMobile');
});

// Conditionally load the Superflow script if the environment variable is true
if (ENV.ADD_SUPER_FLOW) {
    const script = document.createElement('script');
    script.id = "superflowToolbarScript";
    script.dataset.sfPlatform = "googletagmanager-manual";
    script.async = true;
    script.src = "https://cdn.velt.dev/lib/superflow.js?apiKey=sKtLUU7VBwwTeIe5HuBk&projectId=2594218191713008";
    document.head.appendChild(script);
}

app.use(router).use(store).use(GlobalComponents).mount('#app');

if (('serviceWorker' in navigator) && process.env.NODE_ENV !== 'development') {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
            const checkForUpdates = () => {
                registration.update();
            };

            checkForUpdates();
            setInterval(checkForUpdates, 600000);

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            console.log('New content available; please refresh.');
                            showUpdateNotification();
                        } else {
                            console.log('Content cached for offline use.');
                        }
                    }
                };
            };
        }).catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
    });
}

function showUpdateNotification() {
    app.config.globalProperties.$bus.emit('new-version-available');
}