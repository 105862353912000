<template>
    <div class="cards-top-bar-navigation">
        <div class="next"><a href="#">{{ buttonText }}</a></div>
        <SvgIcon :name="iconName" class="icon-wide" />
    </div>
</template>

<script>
export default {
    props: {
        buttonText: String,
        iconName: String,
    },
};
</script>
<style scoped>
.cards-top-bar-navigation {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    position: relative;
    cursor: pointer;
}

.next,
.next a {
    text-align: right;
    font-size: var(--links-link-3-font-size, 2.4rem);
    font-weight: var(--links-link-3-font-weight, 400);
    position: relative;
    flex: 1;
    text-decoration: none;
}
</style>