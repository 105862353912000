<template>
    <div :class="['modal', classes]">
        <div class="modal-content">
            <div class="header">
                <div class="close-button is-right">
                    <SelectableButton class="light small borderless" @click="this.$emit('close')">
                        <SvgIcon name="icon/close" />
                        <div class="bu3-reg">Close</div>
                    </SelectableButton>
                </div>
            </div>
            <slot></slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classes: {
            type: String,
            default: ''
        }
    }
}
</script>