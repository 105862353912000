<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/image3.png" flexClass="flex" nextButtonName="Next" :stepIndex="3">
        <div class="h1 reg">Add Zilch for personal use</div>
        <div class="instructions-block">
            Zilch has public car share hubs in Auckland and Christchurch. You can
            access these cars at any time for one-way trips to and from the
            airport, casual trips and anything else to get you out and about.
        </div>
        <div class="list">
            <div class="instructions">
                <div class="instructions-block">
                    To complete this step you will need:
                </div>
            </div>
            <div class="labels-default">
                <SvgIcon name="payment/card" />
                <div class="labels-text">Personal payment details</div>
                <div class="what-is-this" v-show="false">What is this?</div>
            </div>
        </div>
        <div class="buttons">
            <SelectableButton className="light" @click="handleSkipButton">
                <div class="btn-anchor bu1-semi">No thanks, skip this step</div>
            </SelectableButton>
        </div>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapMutations } from 'vuex';
export default {
    components: {
        OnBoardingCard,
    },
    mounted() {
        this.setNextButtonEnabled(true);
    },
    methods: {
        handleSkipButton() {
            this.$router.push('/onboarding/add-access-card')
        },
        handleSubmit() {
            this.$router.push('/onboarding/personal-card');
        },
        ...mapMutations(['setNextButtonEnabled'])
    }
}
</script>
<style scoped>
.what-is-this {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: right;
    font-size: var(--links-link-1-font-size, 1.4rem);
    font-weight: var(--links-link-1-font-weight, 400);
    text-decoration: underline;
    position: relative;
    width: 18.05rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>