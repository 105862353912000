<template>
    <div :class="['header-alerts', 'container', extraClass]">
        <RowDiv class="alert-content row">
            <ColDiv class="mobile-centered notification-message">
                <SvgIcon v-if="!extraClass.includes('error')" name="icon/close" class="icon-wide" @click="handleClose" />
                <SvgIcon v-if="message.icon" :name="message.icon" class="icon-wide" />
                <div class="message-text">
                    <div class="b1 semi">{{ message.title }}</div>
                    <div class="b1 reg">{{ message.note }}</div>
                </div>
            </ColDiv>
            <ColDiv cols="1" class="mobile-centered" v-if="callBack">
                <SvgIcon name="icon/right" class="icon-wide pull-right pointer" @click="handleClick" />
            </ColDiv>
        </RowDiv>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: Object,
            required: true
        },
        extraClass: {
            type: String,
            default: ''
        },
        callBack: {
            type: Function,
            default: null,
        }
    },
    methods: {
        handleClick() {
            if (this.callBack) {
                this.callBack();
            }
            this.handleClose();
        },
        handleClose() {
            if (!this.extraClass.includes('error')) this.$emit('close');
        },
    }
}
</script>
<style scoped>
.header-alerts {
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    gap: 25px;
    border-radius: 100px;
    color: #042B2F;
    width: -webkit-fill-available;
    border: 1px solid var(--color-grey, #FAFAFA);
    background: var(--lite-gray-grey-1-50percent);
}

.header-alerts.error {
    border: 1px solid var(--notification-colours-error, #CA4D4D);
    background: var(--notification-colours-error-light, #FFECEC);
    color: #872121;
}

.header-alerts.success {
    border: 1px solid var(--notification-colours-correct, #0AAF6C);
    background: var(--notification-colours-correct-light, #D6F6BC);
}

.alert-content {
    display: flex;
    align-items: center;
    gap: 25px;
    align-self: stretch;
}

.row {
    margin: 0px;
}

.notification-message {
    gap: 2.5rem;
}

.message-text {
    gap: 1rem;
}

.message-text, .notification-message {
    display: flex;
}

.pointer {
    cursor: pointer;
}

.col,
[class*=" col-"],
[class*=col-] {
    margin: 0px;
}

@media (max-width: 768px) {
    .mobile-centered {
        display: contents;
    }
}
</style>