<template>
    <div :class="['pill-button', className]">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        className: {
            type: String,
            default: '',
        }
    },
}
</script>
<style scoped>
.pill-button {
    display: inline-flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: lightgray;
    cursor: default;
}

.pill-button.small {
    padding: 5px 15px;
}

.pill-button.xsmall {
    padding: 5px 10px;
}

.light {
    background: var(--company-settings-fav-light, #FBF5DC);
}

.secondary {
    background: var(--zilch-public-fav-5, #E1F3FF);
}

.primary {
    background: var(--color-primary, #A4EA6E);
}

.dark {
    background: var(--bg-secondary-color, #042B2F);
    color: var(--notification-colours-correct-light, #D6F6BC);;
}

.alert {
    background: var(--notification-colours-error-light, #FFECEC);
}

.pointer {
    cursor: pointer;
}
</style>