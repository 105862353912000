<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/image4.png" nextButtonName="Next"
        :stepIndex="4">
        <div class="h1 reg">
            No keys required to get moving
        </div>
        <div class="block-text">
            Your access card allows you to unlock any vehicle you&#039;ve booked.
        </div>
        <div>
            <div class="instructions">
                <div class="instructions-block">How to pick up your card</div>
            </div>
            <div class="form-field-hint b2 reg">
                Pick up your card from the reception in any office.
                <br />
                <br />
                Email us at support@zilch.nz to organise a pick up time or drop by any
                time between 9-5pm.
            </div>
        </div>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy"
                target="_blank">Privacy Policy</a></template>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { setAuth } from '@/utils/functions';
import { mapState, mapMutations } from 'vuex';
export default {
    components: {
        OnBoardingCard
    },
    mounted() {
        this.setNextButtonEnabled(true);
    },
    computed: {
        ...mapState(['onboarding']),
    },
    methods: {
        handleSubmit() {
            this.setIsLoading(true);
            const sessionToken = sessionStorage.getItem('onboardingToken');
            this.$axios.get(`${this.$env.API_HOST}/user/onboarding`, {
                headers: { token: this.onboarding.token || sessionToken }
            }).then(resp => {
                this.$axios.post(`${this.$env.API_HOST}/user/onboarding`, resp.data, { headers: { token: this.onboarding.token || sessionToken } }).then(response => {
                    this.setIsLoading(false);
                    if (response.status === 200 && response.data) {
                        setAuth(response.data);
                        this.$router.push('/onboarding/congratulations');
                    }
                }).catch(error => {
                    this.setIsLoading(false);
                    this.$bus.emit('show-toast-message', {
                        text: 'There was an error during onboarding.',
                        error: true,
                    });
                    console.error("An error occurred while logging in:", error);
                });
            }).catch(err => {
                this.setIsLoading(false);
                this.$bus.emit('show-toast-message', {
                    text: 'There was an error during onboarding.',
                    error: true,
                });
                console.error("An error occurred while logging in:", err);
            });
        },
        ...mapMutations(['setNextButtonEnabled', 'setIsLoading']),
    }
}
</script>