<template>
    <div class="cards-footnote">
        <div class="line-1"></div>
        <div class="footnote">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        footnoteText: String
    }
};
</script>
<style scoped>
.cards-footnote {
    padding: 0rem 4.5rem 5.5rem 4.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.line-1 {
    border-style: solid;
    border-color: var(--lite-gray-grey-2, #edefef);
    border-width: 0.1rem 0 0 0;
    align-self: stretch;
    flex-shrink: 0;
    height: 0rem;
    position: relative;
}

.footnote {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: left;
    font-size: var(--footers-footnote-1-font-size, 1.4rem);
    font-weight: var(--footers-footnote-1-font-weight, 300);
    position: relative;
    align-self: stretch;
}
</style>