import CardDiv from "./components/globals/CardDiv.vue";
import ColDiv from "./components/globals/ColDiv.vue";
import InputComponent from "./components/globals/InputComponent.vue";
import PillButton from "./components/globals/PillButton.vue";
import RowDiv from "./components/globals/RowDiv.vue";
import SelectableButton from '@/components/globals/SelectableButton.vue';
import SvgIcon from "./components/globals/SvgIcon.vue";

export default {
    install(app) {
        app.component('CardDiv', CardDiv);
        app.component('ColDiv', ColDiv);
        app.component('InputComponent', InputComponent);
        app.component('PillButton', PillButton);
        app.component('RowDiv', RowDiv);
        app.component('SelectableButton', SelectableButton);
        app.component('SvgIcon', SvgIcon);
    }
}