<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/image2.png" flexClass="flex"
        nextButtonName="Next" :stepIndex="2">
        <div class="h1 reg ">Add your business credit card</div>
        <div class="form-field">
            <div class="instructions">
                <div class="instructions-block b1 semi">Cardholder</div>
            </div>
            <InputComponent icon-src="people/user" v-model="cardName" type="text" placeholder="Name on card"
                :validation="['required']" @validation="message => handleKeyPress('cardName', message)" />
        </div>
        <div class="form-field">
            <div class="instructions">
                <div class="instructions-block b1 semi">Card details</div>
            </div>
            <div id="card-element" class="b2 reg"></div>
        </div>
    </OnBoardingCard>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {
            cardName: '',
            stripe: null,
            elements: null,
            cardElement: null,
        }
    },
    components: {
        OnBoardingCard
    },
    computed: {
        ...mapState(['onboarding']),
    },
    async mounted() {
        try {
            const stripeApiKey = this.$env.STRIPE_API_KEY;
            if (!stripeApiKey) {
                throw new Error("Stripe API key is not set");
            }
            this.stripe = await loadStripe(stripeApiKey);
            if (!this.stripe) {
                throw new Error("Stripe failed to initialize");
            }

            this.elements = this.stripe.elements();
            this.cardElement = this.elements.create('card', {
                hidePostalCode: true,
                disableLink: true,
                style: {
                    base: {
                        fontFamily: 'DM Sans, sans-serif',
                        fontSize: '14px'
                    }
                }
            });
            this.cardElement.on('change', ({ error }) => { console.error(error) })
            this.cardElement.mount('#card-element');

            console.log('Stripe initialized successfully');
            if (this.onboarding && this.onboarding.first_name && this.onboarding.last_name && (this.onboarding.stripeCustomer === undefined)) {
                const customer = await this.$stripe.customers.create({
                    name: this.onboarding.first_name + ' ' + this.onboarding.last_name,
                    email: this.onboarding.email_address,
                });
                console.log('Stripe customer created', customer);
                this.setOnboarding({ key: 'stripeCustomer', value: customer });
                this.setOnboarding({ key: 'payment_customer_token', value: customer.id });
            }
        } catch (error) {
            console.error("Error initializing Stripe:", error);
        }

        this.setNextButtonEnabled(false);
    },
    unmounted() {
        if (this.cardElement) {
            this.cardElement.unmount();
        }
    },
    watch: {
        onboarding: {
            async handler(newVal) {
                if (newVal && newVal.first_name && newVal.last_name && newVal.stripeCustomer === undefined) {
                    const customer = await this.$stripe.customers.create({
                        name: newVal.first_name + ' ' + newVal.last_name,
                        email: newVal.email_address,
                    });
                    console.log('Stripe customer created', customer);
                    this.setOnboarding({ key: 'stripeCustomer', value: customer });
                    this.setOnboarding({ key: 'payment_customer_token', value: customer.id });
                }
            },
            immediate: true,
            deep: true,
        }
    },
    methods: {
        async handleSubmit() {
            this.setIsLoading(true);
            try {
                if (!this.cardName) {
                    console.error("Cardholder name is required");
                    return;
                }

                const { token, error } = await this.stripe.createToken(this.elements.getElement('card'), {
                    name: this.cardName,
                });

                if (error) {
                    console.error("An error occurred:", error);
                } else {
                    const payment = await this.$stripe.customers.createSource(
                        this.onboarding.stripeCustomer.id,
                        {
                            source: token.id,
                        }
                    );
                    this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {
                        payment_customer_token: this.onboarding.stripeCustomer?.id,
                        payment_method_token1: payment.id,
                        payment_method_tag1: 'business',
                    }, { headers: { token: sessionStorage.getItem('onboardingToken') } }).then(() => {
                        this.setOnboarding({ key: 'payment_method_token1', value: payment.id });
                        this.setOnboarding({ key: 'payment_method_tag1', value: 'Business' });
                        this.$router.push('/onboarding/personal-use');
                        this.setIsLoading(false);
                    }).catch(error => {
                        this.setIsLoading(false);
                        this.$bus.emit('show-toast-message', {
                            text: 'There was an error during onboarding.',
                            error: true,
                        });
                        console.error("An error occurred while logging in:", error);
                    });
                }
            } catch (error) {
                this.setIsLoading(false);
                this.$bus.emit('show-toast-message', { text: error.message, error: true });
                console.error("An error occurred during submission:", error);
            }
        },
        handleKeyPress(name, message) {
            if (message === '') {
                this.setNextButtonEnabled(this.cardName);
            } else {
                this.setNextButtonEnabled(false);
            }
        },
        ...mapMutations(['setNextButtonEnabled', 'setOnboarding', 'setIsLoading'])
    }
}
</script>

<style scoped>
.forms {
    margin-bottom: 20px;
}

.double-form {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.forms-2col {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 47%;
}

#card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
}
</style>