<template>
    <div :class="['screen', { 'onboarding-screen': this.$route.path.includes('onboarding') }]">
        <HeaderView />
        <div v-if="(this.notifications.length > 0)" class="container">
            <RowDiv v-for="(notification, index) in this.notifications" :key="index">
                <div class="col">
                    <HeaderAlert :callBack="notification.callBack" :extraClass="notification.class" @close="handleCloseAlert(index)" :message="notification.message" />
                </div>
            </RowDiv>
        </div>
        <router-view>
        </router-view>
        <div class="container" v-if="isLoggedIn && !isFramed">
            <RowDiv>
                <div class="col is-center">
                    <SelectableButton class="light small borderless" @click="goToFeedback">
                        <div class="bu1 semi">Tell us what you think!</div>
                    </SelectableButton>
                </div>
            </RowDiv>
        </div>
        <CopyrightFooter />
        <NewVersionToast />
        <GenericToast />
        <div class="modal-overlay" v-show="shouldShowNotification">
            <PopUpModal @close="closeWelcome">
                <CardDiv>
                    <div class="b1 reg">
                        <p>
                            <b>Welcome to the Beta Version of the Zilch booking portal!</b>
                        </p>
                        <p>
                            We've given things a makeover and we're excited to introduce you to a fresh new booking
                            experience.
                        </p>

                        <p>
                            If you have any questions, need a hand, or want to provide feedback, don't hesitate to reach
                            out by clicking
                            the <i>'Tell us what you think'</i> button at the bottom of the site.
                        </p>

                        <p><b>Happy booking!</b></p>
                    </div>
                </CardDiv>
            </PopUpModal>
        </div>
    </div>
</template>
<script>
import CopyrightFooter from '@/components/common/CopyrightFooter.vue';
import GenericToast from '@/components/common/GenericToast.vue';
import HeaderView from '@/components/common/HeaderView.vue';
import HeaderAlert from '@/components/common/HeaderAlert.vue';
import NewVersionToast from '@/components/common/NewVersionToast.vue';
import PopUpModal from './components/common/PopUpModal.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { getAuth } from '@/utils/functions';
export default {
    data() {
        return {
            showAlert: false,
            currentMessage: 0,
            messages: [],
        }
    },
    components: {
        CopyrightFooter,
        GenericToast,
        HeaderAlert,
        HeaderView,
        NewVersionToast,
        PopUpModal
    },
    computed: {
        shouldShowNotification() {
            return this.profile && this.profile?.show_notification !== 'false';
        },
        ...mapState(['notifications', 'isLoggedIn', 'isFramed', 'isAdmin', 'profile', 'paymentMethods', 'costCentres', 'expenseCodes']),
    },
    mounted() {
        const auth = getAuth();
        if (!!auth && !this.isLoggedIn) {
            this.loadProfile().then(() => {
                if (this.isAdmin) this.fetchMenu().catch(error => {
                    console.error(error);
                })
                this.fetchPaymentMethods();
            }).catch(() => {
                this.$router.replace({ name: 'Login' })
            });
        }
    },
    methods: {
        handleCloseAlert(index) {
            this.removeNotification(index);
        },
        goToFeedback() {
            sessionStorage.setItem('feedback', 'feedback');
            this.$router.push({ name: 'Profile' });
            this.$bus.emit('switchToFeedback');
        },
        closeWelcome() {
            this.profile.show_notification = 'false';
            this.$axios.put(`${this.$env.API_HOST}/user/update/`, {
                show_notification: 'false',
            }, { headers: { 'Authorization': `Bearer ${getAuth()}` } }).catch(error => {
                console.error("An error occurred while dismissing the welcome screen:", error);
            });
        },
        ...mapMutations(['clearNotifications', 'removeNotification', 'setShowWelcome']),
        ...mapActions(['loadProfile', 'fetchMenu', 'fetchPaymentMethods']),
    },
};
</script>