<template>
    <div class="phone-number-input">
        <!-- Country Code Dropdown -->
        <div class="country-select col-4" ref="countrySelect">
            <div class="selected-country b2 reg" @click="toggleDropdown" :class="className">
                <span class="dropdown-item" v-if="selectedCountry">
                    <img v-show="!isMobile" :src="`/images/flags/${selectedCountry.country_code.toLowerCase()}.png`"
                        :alt="selectedCountry.country_code" width="24px" />
                    {{ selectedCountry.phone_number }}
                </span>
                <span v-else>&nbsp;</span>
                <SvgIcon name="icon/down" class="is-right" />
            </div>

            <!-- Dropdown options -->
            <div v-if="dropdownVisible" class="dropdown" :class="{ 'dropdown-up': dropdownAbove }">
                <div v-for="option in preDialCodes" :key="option.country_code" class="dropdown-item"
                    @click="selectCountry(option)">
                    <img :src="`/images/flags/${option.country_code.toLowerCase()}.png`" :alt="selectCountry.code"
                        width="24px" />
                    {{ option.country_name }} ({{ option.phone_number }})
                </div>
            </div>
        </div>

        <div class="col">
            <!-- Phone Number Input -->
            <InputComponent type="number" v-model="phoneNumber" placeholder="Enter phone number"
                @update:modelValue="updateValue" :className="className">
                <template #extra>
                    <slot name="extra"></slot>
                </template>
            </InputComponent>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        modelValue: {
            type: Object,
            default: () => { },
        },
        drop: {
            type: String,
            default: 'auto'
        },
        className: String
    },
    computed: {
        ...mapState(['isMobile'])
    },
    data() {
        return {
            selectedCountry: null,
            dropdownVisible: false,
            phoneNumber: '',
            dropdownAbove: false,
            preDialCodes: []
        };
    },
    watch: {
        phoneNumber() {
            this.updateValue()
        }
    },
    mounted() {
        this.preDialCodes = [
            { country_code: 'AU', country_name: 'Australia', phone_number: '61' },
            { country_code: 'NZ', country_name: 'New Zealand', phone_number: '64' },
        ];
        this.$axios.get(`${this.$env.API_HOST}/referenceData/countryCodes`).then(response => {
            this.preDialCodes = [
                ...this.preDialCodes,
                ...response.data.filter((el) => (
                    el.country_code !== 'AN' &&
                    el.country_code !== 'AU' &&
                    el.country_code !== 'NZ'
                ))
            ];
        }).catch(e => {
            console.error('Error fetching Country codes; fall back to home countries. Error:', e)
        });
        if (this.modelValue) {
            this.selectedCountry = this.preDialCodes.find((option) => (
                ('+' + option.phone_number === this.modelValue.country_code) || (option.phone_number === this.modelValue.country_code)
            ));
            this.phoneNumber = this.modelValue.phone_number;
        }
        if (this.drop === 'up') this.dropdownAbove = true;
        window.addEventListener('resize', this.adjustDropdownPosition);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustDropdownPosition);
    },
    methods: {
        updateValue() {
            const value = {
                ...this.modelValue,
                country_code: this.selectedCountry ? '+' + this.selectedCountry.phone_number : '',
                phone_number: this.phoneNumber,
            }
            if (value.state) value.state = 'unverified';
            this.$emit('update:modelValue', value);
        },
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        selectCountry(option) {
            this.dropdownVisible = false;
            this.selectedCountry = option;
            this.updateValue();
        },
        adjustDropdownPosition() {
            if (this.drop !== 'auto') {
                this.dropdownAbove = this.drop === 'up';
                return;
            }
            const countrySelect = this.$refs.countrySelect;
            const rect = countrySelect.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            // Check if dropdown will overflow below
            const spaceBelow = viewportHeight - rect.bottom;
            const dropdownHeight = 150; // Adjust based on your actual dropdown height

            if (spaceBelow < dropdownHeight) {
                this.dropdownAbove = true; // Position dropdown above
            } else {
                this.dropdownAbove = false; // Position dropdown below
            }
        }

    }
};
</script>

<style scoped>
.phone-number-input {
    display: flex;
    align-items: center;
}

.country-select {
    position: relative;
    margin-right: 0px;
    margin-left: 0px;
    cursor: pointer;
}

.selected-country {
    align-items: center;
    border: 1px solid transparent;
    color: var(--dark-grey-grey-9-links, #5C6163);
    display: flex;
    flex: 1;
    font-family: var(--regular-font-family, "DmSans-Regular", sans-serif);
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    height: 3.4rem;
    justify-content: space-between;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    background: var(--color-grey, #FAFAFA);
    outline: none;
    border-radius: .8rem;
}

.selected-country:hover {
    border-color: var(--input-hover-color);
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    width: max-content;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
}

.dropdown-up {
    bottom: 100%;
    /* Position dropdown above the selected item */
    top: auto;
    margin-bottom: 5px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    gap: 1.5rem;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

.phone-input {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
}

.flag-icon {
    margin-right: 5px;
}

.white {
    background-color: var(--lite-gray-white, #FFFFFF);
}

@media (max-width: 768px) {
    .country-select {
        max-width: calc(33.33333% - var(--grid-gutter));
    }
}
</style>