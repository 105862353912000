<template>
    <div class="labels-steps">
        <div class="frame-2" v-if="stepIndex != undefined">
            <SvgIcon :name="src" v-for="(src, index) in getImageSrcs" :key="index" class="icon icon-wide" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        stepIndex: {
            type: Number,
            default: undefined
        }
    },
    computed: {
        getImageSrcs() {
            const baseSrc = 'icon/arrow-right';
            const imgSrcs = [];

            for (let i = 1; i <= 4; i++) {
                let src = baseSrc;
                // Check if the current image should have the color parameter based on the step index
                if (i <= this.stepIndex) {
                    src = 'feedback/tick-inverted';
                }
                imgSrcs.push(src);
            }

            return imgSrcs;
        }
    }
};
</script>
<style scoped>
.frame-2 {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 0rem;
    top: 0rem;
}

.labels-steps {
    flex-shrink: 0;
    width: 12.6rem;
    height: 2.4rem;
    position: relative;
    color: var(--font-color, #042B2F);
}

.done img.icon-absolute {
    left: unset;
    top: unset;
}
</style>